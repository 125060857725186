import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseSelah from '../components/course/courseSelah';

const Selah = () => {
  return (
    <>
      <SEO title="Selah" />
      <Layout>
        <CourseSelah />
      </Layout>
    </>
  );
};

export default Selah;
