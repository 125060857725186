import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroSelah from '../image-components/hero/heroSelah';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-selah.png';

const CourseSelah = () => {
  return (
    <>
      <HeroSelah />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>SELAH ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving God via Attentive Listening.</div>
              <p>
                Selah Advance introduces us to the biblical framework of resting
                in God by the way of contemplative prayer. Selah Advance is an
                invitation to be conformed to the image of Christ by becoming
                attentive to Holy Spirit's activity in us as we engage
                Scripture, our environment and daily events as well as in
                silence and solitude.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              The simple vision of Christian life is "the process of being
              conformed to the image of Christ for the sake of others."
            </div>
            <div className={Styles.author}>ROBERT MULHOLLAND</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Grow deeper in rest and intimacy with God, self and others.
                </li>
                <li>
                  Have a biblical framework of and practice various
                  contemplative prayers.
                </li>
                <li>Participate in Selah Retreat.</li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Selah Advance organizes participants to journey together
                through the entire program. In the duration of six weeks,
                participants' primary learning and reflection come from their
                willingness to be vulnerable and open in their stories and
                discoveries to their groups. In so doing, journeying alongside
                one another will open up possibilities for revelation, new
                insights, and personal development.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Selah Advance is paired with an advisor (Joanne Moon) and
                assistants who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Selah Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship and the dynamic
                interaction, which will enrich and deepen its outcome. The
                format will comprise of weekly discussions online. 
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  Book of Psalms gives us rich and ongoing guidance on speaking
                  to God as a people of faith.
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  We no longer have to cover and hide; we can come to God
                  because the finished work of Christ in his death and
                  resurrection.
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>
                  God is always with us through the Holy Spirit. Nothing can
                  separate us from God's love. He is always with us in all the
                  moments and images and sounds and experiences, if we will pay
                  attention through "divine seeing."
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>
                  No SELAH Advance Meeting on Friday (Ekklesia). One Day SELAH
                  Retreat on Saturday.
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  The prayer of examen assists in the challenge to find God in
                  the midst of our daily lives. God is reaching out to make a
                  home with us as we share our experiences with Him and find His
                  presence in all parts of our day.
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>
                  God is central and we find ourselves called into his presence
                  to know the freedom–not the freedom that comes when everything
                  falls into place just right–but of being beloved regardless.
                  We believe God is present and God invites us to rest in Him.
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 7</div>
                <div className={Styles.subheading}>Selahbration</div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Joanne"
              />
              <div className={Styles.guide_name}>Joanne Moon</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor of Ekko Church</div>
                <div>Selah Advance Guide</div>
              </div>
              <p>
                Joanne Moon is a wholehearted wife and mom who is prayerfully
                and playfully engaging the world through conversation with God
                and people. She is in deep study of soul care, spiritual
                formation and spiritual theology with the Church and the world
                in mind. She loves to write, take pictures and tell a story. She
                loves to look you deep in your soul and listen to yours, too.
              </p>
              <p>
                Together with TJ, Jio, Max and Joy, they are navigating the
                adventure that autism brings with God’s enduring companionship
                and the support of family and friends.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseSelah;
