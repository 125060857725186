import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Modal } from 'antd';
import BackgroundImage from 'gatsby-background-image';
import Styles from './tab.module.css';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const TabAbout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "c-about.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [showAboutModal, setAboutModal] = useState(false);

  const modalClose = () => {
    setAboutModal(false);
  };

  return (
    <>
      <div className={Styles.container}>
        <BackgroundImage
          Tag="section"
          className={Styles.img}
          fluid={data.placeholderImage.childImageSharp.fluid}
          onClick={() => setAboutModal(true)}
        >
          <div className={Styles.heading}>ABOUT</div>
        </BackgroundImage>
      </div>
      <Modal
        visible={showAboutModal}
        onCancel={modalClose}
        closable={false}
        footer={null}
      >
        <main>{children}</main>
      </Modal>
    </>
  );
};

export default TabAbout;
